import _isEqualShallow from "is-equal-shallow";
var exports = {};
var equal = _isEqualShallow;
var basic = {};
var cache = {};
/**
 * Expose `regexCache`
 */

exports = regexCache;
/**
 * Memoize the results of a call to the new RegExp constructor.
 *
 * @param  {Function} fn [description]
 * @param  {String} str [description]
 * @param  {Options} options [description]
 * @param  {Boolean} nocompare [description]
 * @return {RegExp}
 */

function regexCache(fn, str, opts) {
  var key = "_default_",
      regex,
      cached;

  if (!str && !opts) {
    if (typeof fn !== "function") {
      return fn;
    }

    return basic[key] || (basic[key] = fn(str));
  }

  var isString = typeof str === "string";

  if (isString) {
    if (!opts) {
      return basic[str] || (basic[str] = fn(str));
    }

    key = str;
  } else {
    opts = str;
  }

  cached = cache[key];

  if (cached && equal(cached.opts, opts)) {
    return cached.regex;
  }

  memo(key, opts, regex = fn(str, opts));
  return regex;
}

function memo(key, opts, regex) {
  cache[key] = {
    regex: regex,
    opts: opts
  };
}
/**
 * Expose `cache`
 */


exports.cache = cache;
exports.basic = basic;
export default exports;
const _cache = exports.cache,
      _basic = exports.basic;
export { _cache as cache, _basic as basic };